@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital@0;1&display=swap');
html body {
  overflow-x: hidden;
}
body {
  margin: 0;
  background: rgb(2,0,36);
  background: #0b0f19;
  background: rgb(12, 0, 50);
  font-family: 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: larger;
}

#root{
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin-bottom: 1em;
  margin-top: 1em;
}
h2, h3{
  margin-top: 1em !important;
  margin-bottom: .7em !important;
}

.card {

  background-color: rgba(255, 255, 255, .04);
  border: 1px solid rgba(255, 255, 255, 0.14);
}

.table {
  color: #fff;
}

.table tr {
  border-color: rgba(255, 255, 255, 0.14) !important;
}

.fadeIn {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

a {
  color: #ffffff;
}

a:hover {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}

.accordion a {
  color: #0d6efd;
}

.accordion a:hover {
  color: #0d6efd;
}

.accordion a:visited {
  color: #0d6efd;
}

.video {
  max-width: 840px;
  max-height: 472px;
  align-items: center;
  justify-content: center;
}

.nav-link {
  font-size: 1.2em !important;
}