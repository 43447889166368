.App {
  text-align: center;
  color: white;
}

.App-container-light {
  background-color: rgba(255, 255, 255, .04);
  padding-bottom: 1em;
}

.App-Logo {
  height: 4em;
  pointer-events: none;
  padding-left: 1em;
  padding-top: .5em;
  transition: height 1s;
}
.App-Logo-Small {
  height: 3em;
  pointer-events: none;
  padding-left: 1em;
  padding-top: .5em;
  transition: height 1s;
}

.App-DownArrow {
  height: 3em;
  width: 3em;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  z-index: 110;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.App-Page {
  padding-top: 5em;
  
}

.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes bounce-7 {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-100px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}



.App-link {
  color: #61dafb;
}


.App-Nav {
  max-width: 100vw;
  min-width: 100vw;
  background-color: transparent !important;
  transition: background-color 1s;
}

.App-Nav-Dark {
  background-color: #070120!important;
  background-color: #0b0f19!important;
  background-color: rgb(0,0,0) !important;
  transition: background-color 1s;

}


.App-Callout{
  font-size: 1.5em;
}

.App-Footer {
  font-size: .8em;
  background-color: black;
  padding: .5em;
  color: #fff;
}

.App-header {
  background-color: #0c0032;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

@media (width <= 991px ) and (orientation: landscape) { 
  
  .App-header {
    background-image: url('../public/phoneLandscape.jpg');
    background-size: cover;
    background-position: center top ;
    background-attachment: scroll;
  }
}


@media (width <= 991px ) and (orientation: portrait) {
  
  .App-header {
    background-image: url('../public/phonePortrait.jpg');
    background-size: cover;
    background-position: center center ;
    background-attachment: scroll;
  }
}


@media (width >= 992px) and (orientation: landscape)  {
  .App-header {
    background-image: url('../public/CoverLG.jpg');
    background-size: cover;
    background-position: center top ;
    background-attachment: scroll;
  }
}

@media (min-width >= 992px) and (orientation: portrait)  {
  .App-header {
    background-image: url('../public/CoverLG-Portrait.jpg');
    background-size: cover;
    background-position: center top ;
    background-attachment: scroll;
  }
}


@media (min-width: 768px) {
  .App-Logo {
    height: 5.5em;
    pointer-events: none;
    padding-left: 2em;
    padding-top: .5em;
    transition: height 1s;
  }
  .App-Logo-Small {
    height: 3em;
    pointer-events: none;
    padding-left: 2em;
    padding-top: .5em;
    transition: height 1s;
  }
}




@media (min-width: 1200px) {  }


@media (min-width: 1400px) {  }