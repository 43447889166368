    #PDFContainer {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    
    .PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    
    .PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    }
    
    .PDFPageOne {
    margin-bottom: 25px;
    }
    
    .PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
    }